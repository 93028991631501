import React from "react";
import { graphql } from "gatsby";
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";

import MainLayout from "./MainLayout";
import SEO from "../atoms/Seo";
import { Box, Typography } from "@material-ui/core";
import ArticleList from "../atoms/articles/ArticleList";
import ArticleListItem from "../atoms/articles/ArticleListItem";
import ContentfulOptions from "./Options";

interface IPolicyData {
  data: {
    post: {
      title: string;
      body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      excerpt: string;
    };
  };
}



const PolicyTemplate: React.FC<IPolicyData> = ({ data }) => {
  const {
    title,
    body,
    excerpt,
    body: { raw },
  } = data.post;

  return (
    <MainLayout>
      <SEO title={title} description={excerpt} />
      <Typography>
        <section>
          <Box component="h2">{title}</Box>
          <Typography align="justify">
            <article>{renderRichText(body, ContentfulOptions)}</article>
          </Typography>
        </section>
      </Typography>
    </MainLayout>
  );
};

export const query = graphql`
  query getPolicies($slug: String!) {
    post: contentfulPolicy(slug: { eq: $slug }) {
      title
      excerpt
      body {
        raw
      }
    }
  }
`;

export default PolicyTemplate;
